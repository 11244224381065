class AssertionError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
  }
}

/**
 * Simple assert function that throws an error if the test is false
 */
export function assert(condition: unknown, msg?: string): asserts condition {
  if (condition === false) throw new AssertionError(msg);
}
